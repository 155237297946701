import { PQRS_TYPE_ID_ARRAY_EN } from '../../../components/vars.global'

const SCHEDULING_T = {
    title: "Appointment Scheduling ",
    text_11: "If required, you can request an appointment with any of the professionals at Curaduría N ° 1 de Bucaramanga.",
    text_c_1_1: "Luis Carlos Parra Salazar",
    text_c_1_2: "Curator",
    text_c_1_3: "The curator processes and issues licenses for subdivision, urbanization, construction and subdivision of properties ",
    text_c_2_1: "Carlos Ulloa",
    text_c_2_2: "Engineer",
    text_c_2_3: "The Engineer reviews structural projects ",
    text_c_3_1: "Maria Margarita",
    text_c_3_2: "Lawyer",
    text_c_3_3: "Administrative and Urban Law Specialist",
    text_c_4_1: "Jose Triana",
    text_c_4_2: "Architect",
    text_c_4_3: "Project reviewer and consulting ",
    text_c_5_1: "Linda Paez",
    text_c_5_2: "Lawyer",
    text_c_5_3: "Law Specialist and consulting",
    form_title: "MAKE YOUR APPOINTMENT WITH: ",
    form_subTitle_1: "Calendar ",
    form_subTitle_2: "Contact information",
    form_subTitle_3: "Appointment Information",
    form_calendar_locale: "en",
    form_box_11: "Availability: ",
    form_box_12: "Select a Date",
    form_box_13: "Looking for availability...",
    form_box_14: "There is an appointment available for this day",
    form_box_15: "No Appointment available for this day",
    form_box_152: "No Appointment available for this day, this is a weekend",
    form_box_153: "No Appointment available for this day, this is a holyday",
    form_box_154: "No Appointment available for this day, the worker is not taking appointments this day",
    form_box_155: "No Appointment available for this day and/or hours, check another day or hour",
    form_box_16: "All initial appointments last from 30 minutes to 45 minutes.",
    form_name: "Name and surname(s)",
    form_type_id_0: "Tipo de Identificacion",
    form_type_id: PQRS_TYPE_ID_ARRAY_EN,
    form_number_id: "Identification number",
    form_email: "Email",
    form_number_mobile: "Mobile phone",
    form_appointment_type: "Appointment Type",
    form_appointment_type_0: "Virtual",
    form_appointment_type_1: "Face-to-face",
    form_time: "Appointment Time",
    form_time_0: "8:00 - 9:00 am",
    form_time_1: "9:00 - 10:00 am",
    form_time_2: "10:00 - 11:00 am",
    form_time_3: "11:00 - 12:00 am",
    form_time_4: "2:00 - 3:00 pm",
    form_time_5: "3:00 - 4:00 pm",
    form_time_6: "4:00 - 5:00 pm",
    form_motive: "Reason for Appointment",
    form_motive_0: "Analysis of standards for property",
    form_motive_1: "Analysis of standards applicable to a property",
    form_motive_2: "Explanation and analysis of observation reports",
    form_motive_3: "BIN standards advisory",
    form_motive_4: "Other motives",
    form_motive_5: "Inquiries",
    form_content: "Reason for appointment",
    form_btn_1: "Schedule Appointment",
    form_btn_2: "Close",
}

export default SCHEDULING_T;