// TYPE OF PUBLICATIONS
export const PUBLISH_TYPE_ARRAY = [
  'Licencias urbanisticas',
  'Otras actuaciones',
  'MLV-PRO-REV',
  'Aviso vecinos colindantes',
  'Citación para notificación personal',
  'Notificaciones de avisos',
  'Actos generales y respuestas pqrs',
  'Resoluciones',
]

export const PUBLISH_TYPE_ARRAY_EN = [
  'Administrative acts',
  'Other acts',
  'Notifications to neighbors ',
  'Press releases',
  'Reanudacion de terminos',
  'Resumption of terms',
  'Notice notifications',
  'Right of Petition Response ',
  'Response PQRS',
  'Other publications',
  'Resolutions',
  'Extension',
  'Revalidation '
]

export const PQRS_TYPE_ARRAY = [
  'Petición',
  'Queja',
  'Reclamo',
  'Sugerencia',
  'Denunció',
  'Felicitaciones',
  'Recurso',
]

export const PQRS_TYPE_ARRAY_EN = [
  'Petition',
  'Complain',
  'Claim',
  'Suggestion',
  'Denounce',
  'Congratulations',
  'Resource',
]


export const PQRS_MASTER_TYPE_ARRAY = [
  'Petición ordinaria',
  'Peticiones de información y copias o fotocopias de documentos',
  'Consulta a la Función Pública',
  'Peticiones de documentos y de información provenientes de otras entidades o autoridades públicas',
  'Examen de documentos',
  'Peticiones de expedición de copias o fotocopias de los expedientes y certificaciones',
  'Solicitudes de informes que se reciben de los Honorables Congresistas en ejercicio',
  'Solicitud de documentos por parte de las Cámaras Legislativas',
  'Solicitudes de asesoría y capacitación'
]

export const PQRS_MASTER_TYPE_ARRAY_EN = [
  'Ordinary request',
  'Requests for information and copies or photocopies of documents ',
  'Consultation with the Public Function',
  'Requests for documents and information from other entities or public authorities',
  'Document examination',
  'Requests for the issuance of copies or photocopies of the files and certifications',
  'Requests for reports received from the Honorable Congressmen in office ',
  'Request for documents by the Legislative Chambers',
  'Consultation and training requests'
]

export const PQRS_TYPE_PERSON_ARRAY = [
  'Persona Natural',
  'Persona Jurídica',
  'Menores y Adolescentes apoderados',
]

export const PQRS_TYPE_PERSON_ARRAY_EN = [
  'Natural Person',
  'Legal person',
  'Minors and Adolescents empowered',
]

export const PQRS_TYPE_ID_ARRAY = [
  'Cédula de Ciudadanía',
  'Cédula de extranjería',
  'Registro Civil',
  'Tarjeta de identidad',
  'Otro',
]

export const PQRS_TYPE_ID_ARRAY_EN = [
  'Citizenship ID',
  'Foreigner ID ',
  'Civil registration ID',
  'Identity card',
  'Other',
]

export const PQRS_TYPE_CHANNEL_ARRAY = [
  'Canal Electrónico (Formulario Página Web)',
  'Canal Electrónico (Chat)',
  'Canal Electrónico (Email)',
  'Canal Escrito (Correo Postal)',
  'Canal Escrito (Radicación Personal)',
  'Canal Escrito (Buzón de Sugerencias)',
  'Canal Escrito (Fax)',
  'Canal Presencial (Voz a Voz)',
  'Canal Telefónico'
]

export const PQRS_TYPE_CHANNEL_ARRAY_EN = [
  'Electronic Channel (Website Form) ',
  'Electronic Channel (Chat)',
  'Electronic Channel (Email)',
  'Written Channel (Postal Mail)',
  'Written Channel (Personal Filing)',
  'Written Channel (Suggestion Box)',
  'Written Channel (Fax)',
  'Presential Channel (Voiced)',
  'Telephone Channel'
]

export const PQRS_TYPE_STATUS_ARRAY = [
  'PENDIENTE',
  'TERMINADA'
]

export const PQRS_TYPE_STATUS_ARRAY_EN = [
  'PENDING',
  'RESOLVED'
]


export const PQRS_TIME_ARRAY = [
  15, 10, 30, 10, 10, 10, 5, 10, 30
]
/* 
  15 days Ordinaria
  10 days Peticiones de información y copias o fotocopias de documentos
  30 days Consulta a la Función Pública 
  10 days Peticiones de documentos y de información provenientes de otras entidades o autoridades públicas
  10 days Examen de documentos
  10 days Peticiones de expedición de copias o fotocopias de los expedientes y certificaciones
  5  days Solicitudes de informes que se reciban de los Honorables Congresistas en ejercicio
  10 days Solicitud de documentos por parte de las Cámaras Legislativas
  30 days Solicitudes de asesoría y capacitación
*/


export const SUBMIT_ARC_AREA_ACTIVIDAD = [
  'Residencial - R1 - Residencial meta',
  'Residencial - R2 - Residencial con comercio y servicio localizado',
  'Residencial - R2 - Sin eje comercial',
  'Residencial - R3 - Residencial mixta - vivienda, comercio y servicio',
  'Residencial - R4 - Residencial con actividad económica',
  'Comercial y de Servicios - C1 - Comercial y de servicios empresariales',
  'Comercial y de Servicios - C2 - Comercial y de servicios livianos o al por mayor',
  'Comercial y de Servicios - C3 - Comercial y de servicios pesados',
  'Comercial y de Servicios - CE - Comercial de eje en Area de Actividades R-2',
  'Dotacional - D - Dotacional',
  'Dotacional - D - Dotacional Recreativo',
  'Industrial - I - Industria',
  'Multiple - M1 - Multiple centralidad',
  'Multiple - M2 - Multiple grandes establecimientos',
]

export const SUBMIT_ARC_TRATAMIENTO_URBANISTICO = [
  'Desarrollo - TD - Desarrollo',
  'Consolidación - TC-1 - Consolidación Urbana',
  'Consolidación- TC-2 - Consolidación con generación de espacio publico',
  'Consolidación- TRD - Redesarrollo',
  'Renovación - TRA-1 - Reactivación',
  'Renovación - TRA-2 - Reactivación',
  'Renovación - TRA-3 - Reactivación de sector urbano especial',
  'Mejoramiento Integral - TMI-1 - Complementario',
  'Mejoramiento Integral - TMI-2 - Reordenamiento',
  'Conservación - TCoU - Para inmuebles de interes cultural del grupo urbano',
  'Conservación - TCoA-1 - Para inmuebles de interés cultural del grupo arquitectónico agrupación',
  'Conservación - TCoA-2 - Para inmuebles de interés cultural del grupo arquitectónico individual',
]

export const SUBMIT_ARC_ZONS_RESTRICCION = [
  '1. Occidente, escarpe de Malpaso y otros escarpes',
  '2. Norte la Esperanza',
  '3. Morrorico',
  '4. Oriental',
  '5. Rio de Oro, Suratá  Quebrada la Iglesia',
  '6. Áreas de drenaje',
  '7. Área de amortiguación 1 de los Escarpes',
  '8. Zona norte occidental',
  '9. El Pablón',
  '10. Zona via Palenque - Café Madrid y algunas terrazas',
  '11. Zona llenos',
  '12. Meseta de Bucaramanga',
  '13. Área de amortiguación 2 de los Escarpes',
]

export const SUBMIT_ARC_AMENAZA = [
  'Zona I',
  'Zona II',
  'Zona III',
  'Zona IV',
  'Zona V',
  'Zona de Inundacion',
  'Zona Sismica',
]

export const EJES = [
  'Eje Cra. 33',
  'Eje Cra. 27',
  'Eje Cra./Dig. 15',
  'Eje la Rosita',
  'Boul Bolivar/Sder',
  'Demas Zona',
  'Autop Florida',
]