import guide_01 from './guide_01.md'
import guide_02 from './guide_02.md'
import guide_03 from './guide_03.md'
import guide_041 from './guide_041.md'
import guide_042 from './guide_042.md'
import guide_043 from './guide_043.md'
import guide_044 from './guide_044.md'
import guide_045 from './guide_045.md'
import guide_046 from './guide_046.md'

const IndexList = [
    { pre: '1', label: 'Login de la Aplicacion', ref: '1-login-de-la-aplicacion', md: guide_01, },
    { br: true },
    { pre: '2', label: 'Panel de control', ref: '2-panel-de-control', md: guide_02, },
    { br: true },
    { pre: '3', label: 'Radicacion', ref: '3-radicacion', md: guide_03, },
    { pre: '3.1', label: 'Nueva Licencia', ref: '31-nueva-licencia', md: guide_03, },
    { pre: '3.2', label: 'Buscar Licencia', ref: '32-buscar-licencia', md: guide_03, },
    { pre: '3.3', label: 'Listado de Licencias', ref: '33-listado-de-licencias', md: guide_03, },
    { pre: '3.3.1', label: 'Iconos de Progresion', ref: '331-iconos-de-progresion', md: guide_03, },
    { pre: '3.3.2', label: 'Menu de Licencia', ref: '332-menu-de-licencia', md: guide_03, },
    { br: true },
    { pre: '4', label: 'Submódulo de detalles', ref: '41-submodulo-de-detalles', md: guide_041, },
    { br: true },
    { pre: '4.1.1', label: 'Metadatos de la Solicitud', ref: '411-metadatos-de-la-solicitud', md: guide_041, },
    { pre: '4.1.2', label: 'Información de la solicitud', ref: '412-informacion-de-la-solicitud', md: guide_041, },
    { pre: '4.1.3', label: 'Información del predio', ref: '413-informacion-del-predio', md: guide_041, },
    { pre: '4.1.4', label: 'Información de vecinos colindantes', ref: '414-informacion-de-vecinos-colindantes', md: guide_041, },
    { pre: '4.1.5', label: 'Linderos, Dimensiones y Áreas', ref: '415-linderos-dimensiones-y-areas', md: guide_041, },
    { pre: '4.1.6', label: 'Titulares y profesionales responsables', ref: '416-titulares-y-profesionales-responsables', md: guide_041, },
    { pre: '4.1.6.1', label: 'Titulares de la Licencia', ref: '4161-titulares-de-la-licencia', md: guide_041, },
    { pre: '4.1.6.2', label: 'Profesionales responsables', ref: '4162-profesionales-responsables', md: guide_041, },
    { pre: '4.1.6.3', label: 'Responsable de la solicitud', ref: '4163-responsable-de-la-solicitud', md: guide_041, },
    { pre: '4.1.7', label: 'Lista de chequeo', ref: '417-lista-de-chequeo', md: guide_041, },
    { pre: '4.1.8', label: 'Dato varios', ref: '418-datos-varios', md: guide_041, },
    { pre: '4.1.9', label: 'Informes', ref: '419-informes', md: guide_041, },
    { pre: '4.1.10', label: 'Acta', ref: '4110-acta', md: guide_041, },
    { br: true },
    { pre: '4.2', label: 'Submódulo de Tiempos', ref: '42-submodulo-de-tiempos', md: guide_042, },
    { pre: '4.2.1', label: 'Anuncios de profesionales', ref: '421-anuncios-de-profesionales', md: guide_042, },
    { pre: '4.2.2', label: 'Control de tiempos y fechas de la solicitud', ref: '422-control-de-tiempos-y-fechas-de-la-solicitud', md: guide_042, },
    { pre: '4.2.2.1', label: 'Eventos principales', ref: '4221-eventos-principales', md: guide_042, },
    { pre: '4.2.2.2', label: 'Eventos secundarios', ref: '4222-eventos-secundarios', md: guide_042, },
    { pre: '4.2.2.3', label: 'Gráfica de eventos', ref: '4223-grafica-de-eventos', md: guide_042, },
    { pre: '4.2.3', label: 'Control de procesos de desistimientos', ref: '423-control-de-procesos-de-desistimientos', md: guide_042, },
    { pre: '4.2.3.1', label: 'Proceso de desistimiento', ref: '4231-proceso-de-desistimiento', md: guide_042, },
    { pre: '4.2.3.2', label: 'Iniciar proceso de desistimiento', ref: '4232-iniciar-proceso-de-desistimiento', md: guide_042, },
    { pre: '4.2.3.3', label: 'Finalizar proceso de desistimiento', ref: '4233-finalizar-proceso-de-desistimiento', md: guide_042, },
    { pre: '4.2.3.4', label: 'Asistente de correos', ref: '4234-asistente-de-correos', md: guide_042, },
    { br: true },
    { pre: '4.3', label: 'Submódulo Documentos', ref: '43-submodulo-documentos', md: guide_043, },
    { pre: '4.3.1', label: 'Gestion documental', ref: '431-gestion-documental', md: guide_043, },
    { pre: '4.3.1.1', label: 'Documentos digitalizados', ref: '4311-documentos-digitalizados', md: guide_043, },
    { pre: '4.3.1.2', label: 'Documentos de ventanilla única', ref: '4312-documentos-de-ventanilla-unica', md: guide_043, },
    { pre: '4.3.2', label: 'Anexar documentos', ref: '432-anexar-documentos', md: guide_043, },
    { pre: '4.3.3', label: 'Lista general de chequeo de documentos', ref: '433-lista-general-de-chequeo-de-documentos', md: guide_043, },
    { pre: '4.3.4', label: 'Generar documentos automaticos', ref: '434-generar-documentos-automaticos', md: guide_043, },
    { pre: '4.3.4.1', label: 'PDF Formulario unico nacional', ref: '4341-pdf-formulario-unico-nacional', md: guide_043, },
    { pre: '4.3.4.2', label: 'PDF Lista de chequeo', ref: '4342-pdf-lista-de-chequeo', md: guide_043, },
    { pre: '4.3.4.3', label: 'Documento de recordatorio incompleto', ref: '4343-documento-de-recordatorio-incompleto', md: guide_043, },
    { pre: '4.3.4.4', label: 'Documento de confirmacion legal y debida forma', ref: '4344-documento-de-confirmacion-legal-y-debida-forma', md: guide_043, },
    { pre: '4.3.4.5', label: 'Valla', ref: '4345-valla', md: guide_043, },
    { pre: '4.3.4.6', label: 'Sello', ref: '4346-sello', md: guide_043, },
    { pre: '4.3.4.7', label: 'Documento de citacion a vecinos', ref: '4347-documento-de-citacion-a-vecinos', md: guide_043, },
    { pre: '4.3.4.8', label: 'Hoja de control documental', ref: '4348-hoja-de-control-documental', md: guide_043, },
    { pre: '4.3.4.9', label: 'Notificacion licencia - Renuncia de terminos', ref: '4349-notificacion-licencia---renuncia-de-terminos', md: guide_043, },
    { pre: '4.3.5', label: 'Control de documentacion especial', ref: '435-control-de-documentacion-especial', md: guide_043, },
    { pre: '4.3.5.1', label: 'Control de documento de reconocimiento', ref: '4351-control-de-documento-de-reconocimiento', md: guide_043, },
    { pre: '4.3.5.2', label: 'Certificaciones', ref: '4352-certificaciones', md: guide_043, },
    { br: true },
    { pre: '4.4', label: 'Submódulo Actualizar', ref: '44-submodulo-actualizar', md: guide_044, },
    { pre: '4.4.1', label: 'Metadatos de la solicitud', ref: '441-metadatos-de-la-solicitud', md: guide_044, },
    { pre: '4.4.2', label: 'Identificacion de la solicitud', ref: '442-identificacion-de-la-solicitud', md: guide_044, },
    { pre: '4.4.3', label: 'Informacion del predio', ref: '443-informacion-del-predio', md: guide_044, },
    { pre: '4.4.4', label: 'Informacion de vecinos colindantes', ref: '444-informacion-de-vecinos-colindantes', md: guide_044, },
    { pre: '4.4.5', label: 'Linderos, dimnesiones y areas', ref: '445-linderos-dimnesiones-y-areas', md: guide_044, },
    { pre: '4.4.6', label: 'Titulares y profesionales responsables', ref: '446-titulares-y-profesionales-responsables', md: guide_044, },
    { pre: '4.4.6.1', label: 'Titulares de la licencia', ref: '4461-titulares-de-la-licencia', md: guide_044, },
    { pre: '4.4.6.2', label: 'Profesionales responsables', ref: '4462-profesionales-responsables', md: guide_044, },
    { pre: '4.4.6.3', label: 'Responsable de la solicitud', ref: '4463-responsable-de-la-solicitud', md: guide_044, },
    { pre: '4.4.7', label: 'Anexo de construcción sostenble', ref: '447-anexo-de-construccion-sostenble', md: guide_044, },
    { pre: '4.4.8', label: 'Descargar PDF', ref: '448-descargar-pdf', md: guide_044, },
    { br: true },
    { pre: '4.5', label: 'Submódulo Chequeo', ref: '45-submodulo-chequeo', md: guide_045, },
    { pre: '4.5.1', label: 'Identificación de la Solicitud', ref: '451-identificacion-de-la-solicitud', md: guide_045, },
    { pre: '4.5.2', label: 'Identificacion del solicitante', ref: '52-identificacion-del-solicitante', md: guide_045, },
    { pre: '4.5.3', label: 'Identificacion del encargado de la revision', ref: '453-identificacion-del-encargado-de-la-revision', md: guide_045, },
    { pre: '4.5.4', label: 'Condicion de la radicacion', ref: '454-condicion-de-la-radicacion', md: guide_045, },
    { pre: '4.5.5', label: 'Control de LyDF', ref: '455-control-de-lydf', md: guide_045, },
    { pre: '4.5.6', label: 'Lista general de chequeo de documentos', ref: '456-lista-general-de-chequeo-de-documentos', md: guide_045, },
    { pre: '4.5.7', label: 'Descargar PDF', ref: '457-descargar-pdf', md: guide_045, },
    
];

export default IndexList;