import { PQRS_TYPE_ID_ARRAY } from '../../../components/vars.global'

const SCHEDULING_T = {
    title: "Agendamiento de Citas",
    text_11: "En caso de requerirlo usted puede solicitar una cita con cualquiera de los profesionales de la Curaduría N°1 de Bucaramanga.",
    text_c_1_1: "Luis Carlos Parra Salazar",
    text_c_1_2: "Curador",
    text_c_1_3: "Tramita y expide licencias de parcelación, urbanización, construcción y subdivisión.",
    text_c_2_1: "Carlos Ulloa",
    text_c_2_2: "Ingeniero",
    text_c_2_3: "Revisor de proyectos estructurales",
    text_c_3_1: "Maria Margarita",
    text_c_3_2: "Abogada",
    text_c_3_3: "Especialista en derecho administrativo y urbano",
    text_c_4_1: "Jose Triana",
    text_c_4_2: "Arquitecto",
    text_c_4_3: "Revisor de proyectos y consultoría",
    text_c_5_1: "Linda Paez",
    text_c_5_2: "Abogada",
    text_c_5_3: "Abogada revisora de proyectos",
    text_c_6_1: "Nathalia Parra",
    text_c_6_2: "Arquitecta",
    text_c_6_3: "Arquitecta revisora de proyectos",
    form_title: "AGENDE SU CITA CON: ",
    form_subTitle_1: "Calendario",
    form_subTitle_2: "Información de Contacto",
    form_subTitle_3: "Información de la Cita",
    form_calendar_locale: "es",
    form_box_11: "Disponibilidad: ",
    form_box_12: "Seleccione una Fecha",
    form_box_13: "Buscando disponibilidad...",
    form_box_14: "Si hay cita disponible para este día",
    form_box_15: "No Hay Cita disponible para esta día",
    form_box_152: "No Hay Cita disponible para esta día, Este es un fin de semana",
    form_box_153: "No Hay Cita disponible para esta día, Este es un dia Festivo",
    form_box_154: "No Hay Cita disponible para esta día, El profesional no recibe citas este dia",
    form_box_155: "No Hay Cita disponible para esta día y/u hora, revise otro dia u hora",
    form_box_16: "Todas las citas iniciales tienen una duración de 20 minutos a 30 minutos.",
    form_name: "Nombre y Apellido(s)",
    form_type_id_0: "Tipo de Identificación",
    form_type_id: PQRS_TYPE_ID_ARRAY,
    form_number_id: "Número de Identificación",
    form_email: "Correo Electrónico",
    form_number_mobile: "Telefono Movil",
    form_appointment_type: "Tipo de Cita",
    form_appointment_type_0: "Virtual",
    form_appointment_type_1: "Presencial",
    form_time: "Hora de la Cita",
    form_time_0: "8:00 - 9:00 am",
    form_time_1: "9:00 - 10:00 am",
    form_time_2: "10:00 - 11:00 am",
    form_time_3: "11:00 - 12:00 am",
    form_time_4: "2:00 - 3:00 pm",
    form_time_5: "3:00 - 4:00 pm",
    form_time_6: "4:00 - 5:00 pm",
    form_motive: "Motivo de la Cita",
    form_motive_0: "Análisis de normas para predio",
    form_motive_1: "Análisis de normas aplicables a un predio",
    form_motive_2: "Explicación y análisis de actas de observación",
    form_motive_3: "Asesoría de normas BIM",
    form_motive_4: "Otros motivos",
    form_motive_5: "Consultas",
    form_content: "Razón de la cita",
    form_btn_1: "Agendar Cita",
    form_btn_2: "Cerrar",
}

export default SCHEDULING_T;