const LIQUIDATOR_T = {
    form_project_0: "Project Type",
    form_project_1: "Recognition and Build License",
    form_project_2: "Urban License",
    form_project_3: "Building recognition",
    form_project_4: "Subdivision License",
    form_project_5: "Parcelling license",
    form_project_6: "Public Space Intervention and Ocupation License",
    form_use_0: "Type of use",
    form_use_1: "Living place",
    form_use_2: "Other uses (Comerce, Services, Institutional, Industrial)",
    form_social_0: "Housing Type",
    form_social_1: "Social Interest Household (VIS)",
    form_social_2: "NO Social Interest Household (NO VIS)",
    form_social_3: "Priority Social Interest Household (VIP)",
    form_strata_0: "Stratum",
    form_strata_1: "Stratum 1 & 2",
    form_strata_2: "Stratum 3",
    form_strata_3: "Stratum 4",
    form_strata_4: "Stratum 5",
    form_strata_5: "Stratum 6",
    form_area: "Area",
    form_module_0: "Modality or type of act",
    form_module_1: "NEW BUILD",
    form_module_2: "EXTENSION",
    form_module_3: "MODIFICATION",
    form_module_31: "MODIFICATION (ONLY)",
    form_module_4: "STRUCTURAL REINFORCEMENT",
    form_module_5: "ADEQUATION (WITHOUT CONSTRUCTION)",
    form_module_51: "ADEQUATION  (WITH CONSTRUCTION)",
    form_module_6: "TOTAL DEMOLITION",
    form_module_7: "PARTIAL DEMOLITION",
    form_module_8: "ENCLOSURE",
    form_module_9: "RESTORATION",
    form_module_10: "REBUILD",
    form_module_11: "RECOGNITION",
    form_btn: "Calculate",
    title: "Liquidator",
    subTitle_1: "Enter your details here",
    subTitle_2: "Expense Results",
    text_21: "Fixed charge",
    text_22: "Variable Charge",
    text_23: "Subtotal",
    text_24: "IVA",
    text_25: "Total",
    text_26: "Expenses",
}

export default LIQUIDATOR_T;